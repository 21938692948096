<template>
  <div>
    <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
      <v-row class="no-gutters">
        <v-col
          xl="8"
          offset-xl="2"
          md="10"
          offset-md="1"
          sm="12"
          offset-sm="0"
          class="py-0"
        >
          <v-data-table
            :headers="headers"
            :items="kisisellestirmeler"
            :search="search"
            :disable-sort="$vuetify.breakpoint.smAndDown ? true : false"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
            style="border-radius:6px"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="primary"
                height="120px"
                dark
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'rounded-t-0'
                    : 'rounded-t-lg pl-3'
                "
              >
                <v-toolbar-title class="d-flex align-center">
                  <v-icon large>
                    mdil-tag
                  </v-icon>
                  <span class="pl-6 text-h4">{{
                    $t("kisisellestirmeler")
                  }}</span>
                </v-toolbar-title>
                <v-spacer />
                <div
                  v-if="$vuetify.breakpoint.smAndDown ? false : true"
                  class="d-flex py-6 align-center"
                >
                  <v-text-field
                    v-model="search"
                    class="pr-6"
                    append-icon="mdi-magnify"
                    :label="$t('ara')"
                    hide-details
                  />
                </div>
                <v-btn
                  color="accent"
                  dark
                  depressed
                  class="mb-2"
                  @click="dialog = true"
                >
                  {{ $t("yeniEkle") }}
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:header.ad="{ item }">
              {{ $t("ad") }}
            </template>
            <template v-slot:header.ilaveFiyat="{ item }">
              {{ $t("ilaveFiyat") }}
            </template>
            <template v-slot:header.durum="{ item }">
              {{ $t("durum") }}
            </template>

            <template v-slot:item.ilaveFiyat="{ item }">
              <div>
                {{ item.ilaveFiyat | currency }}
              </div>
            </template>
            <template v-slot:item.durum="{ item }">
              <v-icon v-if="item.durum" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="primary" medium>
                mdil-cancel
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <div>
      <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        scrollable
        max-width="500px"
        transition="dialog-bottom-transition"
      >
        <v-form ref="form" v-model="valid" :disabled="disabled" lazy-validation>
          <v-card>
            <v-card-title class="py-3 py-md-6">
              <div class="text-center text-h4  text-md-h3 font-weight-medium">
                {{ formTitle }}
              </div>
              <v-spacer />
              <v-btn
                x-small
                fab
                elevation="0"
                color="grey lighten-2"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />

            <v-card-text class="px-2 py-0" style="max-height: 100%;">
              <div id="scrollItem" class="scroll pa-3">
                <v-text-field
                  v-model="editedItem.ad"
                  outlined
                  rounded
                  hide-details
                  class="mb-6 mb-md-9"
                  :label="$t('ad')"
                  :counter="30"
                  :rules="nameRules"
                  required
                />

                <v-currency-field
                  v-model="editedItem.ilaveFiyat"
                  :label="$t('ilaveFiyat')"
                  outlined
                  rounded
                  hide-details
                  class="mb-6 mb-md-9"
                  prefix="€"
                />

                <v-switch
                  v-model="editedItem.durum"
                  color="primary"
                  inset
                  :label="`${editedItem.durum ? $t('onayli') : $t('onaysiz')}`"
                />
              </div>
            </v-card-text>

            <v-divider />
            <v-card-actions class=" px-3 primary rounded-0">
              <div class="px-3" style="width:100%">
                <v-btn
                  block
                  :large="$vuetify.breakpoint.smAndDown ? false : true"
                  depressed=""
                  class="rounded-pill text-capitalize text-body-1"
                  color="primary"
                  @click="save()"
                >
                  {{ formAction }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { veri, depo } from "./../db";

export default {
  name: "KisisellestirmelerView",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    kisisellestirmeler: [],
    editedIndex: -1,
    editedItem: {
      ad: "",
      ilaveFiyat: 0,
      durum: true
    },
    defaultItem: {
      ad: "",
      ilaveFiyat: 0,
      durum: true
    },
    disabled: false,
    valid: true,
    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length <= 30) || "Name must be less than 30 characters"
    ]
  }),
  firebase: {
    kisisellestirmeler: veri.ref("kisisellestirmeler")
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("yeniEkle") : this.$t("duzenle");
    },
    formAction() {
      return this.editedIndex === -1 ? this.$t("olustur") : this.$t("guncelle");
    },
    headers() {
      return [
        {
          text: this.$t("ad"),
          value: "ad"
        },

        {
          text: this.$t("fiyat"),
          align: "right",
          sortable: false,
          value: "ilaveFiyat"
        },
        {
          text: this.$t("durum"),
          align: "right",
          sortable: false,
          value: "durum"
        },
        {
          text: "",
          value: "actions",
          align: "right",
          filterable: false
        }
      ];
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.kisisellestirmeler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      confirm(this.$t("silOnay")) &&
        veri.ref("kisisellestirmeler/" + item[".key"]).remove();
    },

    close() {
      this.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          veri
            .ref(
              "kisisellestirmeler/" +
                this.kisisellestirmeler[this.editedIndex][".key"]
            )
            .set(this.editedItem)
            .then(this.close());
        } else {
          veri
            .ref("kisisellestirmeler")
            .push(this.editedItem)
            .then(this.close());
        }
      }
    }
  }
};
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 162px;
}
</style>
